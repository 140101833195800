<template>
  <div class="lcf-message-list">
    <NavBar
      title="个人消息"
      left-arrow
      fixed
      @click-left="$router.replace({name: 'Personal'})"
      placeholder
    />
    <PullRefresh v-model="refreshing" @refresh="onRefresh">
      <List
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <ListTransition>
          <div
            class="lcf-message-item"
            v-for="(v, k) in messages"
            :key="k"
            :data-index="k"
            :data-page-size="pageSize"
          >
            <div class="lcf-message-item--head">
              <span class="rdm-icon"></span>
              <span class="lcf-message-time">{{ v.at }}</span>
            </div>
            <div
              class="lcf-message-item--body"
              @click="showMessageDetail(v.id, k)"
              v-waves
            >
              <span class="lcf-message-title van-ellipsis--12">{{
                v.subject
              }}</span>
              <!-- <span class="lcf-message-instr" v-show="contents[k]">{{
              contents[k]
            }}</span> -->
            </div>
          </div>
        </ListTransition>
      </List>
    </PullRefresh>
    <ActionSheet v-model="detail.show" cancel-text="关闭">
      <div class="lcf-action-sheet">{{ detail.content }}</div>
      <template #description>
        <div>{{ detail.title }}</div>
      </template>
    </ActionSheet>
    <div v-if="finished" class="lcf-common-bottom-tip">
      市场有风险，出借需谨慎
    </div>
  </div>
</template>

<script>
import { NavBar, Notify, PullRefresh, List, ActionSheet } from 'vant'
import { mapActions } from 'vuex'

export default {
  components: {
    NavBar,
    PullRefresh,
    List,
    ActionSheet,
  },
  data() {
    return {
      loading: false,
      finished: false,
      refreshing: false,
      error: false,
      toPage: 1,
      pageSize: 10,
      totalPage: null,

      messages: [],
      contents: [],

      detail: {
        title: '',
        content: '',
        show: false,
      },
    }
  },
  mounted() {
    this.$once('hook:beforeDestroy', function () {
      this.messages = []
      this.contents = []
    })
  },
  methods: {
    ...mapActions(['requestMessages', 'requestMessageDetail']),
    async showMessages() {
      let messages = []
      let error, data
      ;[error, data] = await to(
        this.requestMessages({
          page: this.toPage,
          pageSize: this.pageSize,
        })
      )

      if (error) {
        Notify({
          type: 'warning',
          message: error.message || error,
        })
      }

      if (data && data.code === -1) {
        messages = data.data
        this.totalPage = data.total
      }
      return messages
    },
    async onLoad(reloadData) {
      if (this.toPage > this.totalPage && this.totalPage != null) {
        this.finished = true
        return
      }

      let error, messages
      ;[error, messages] = await to(this.showMessages(this.toPage))

      if (error) {
        this.refreshing = false
        this.loading = false
        this.error = true
        return
      }

      if (reloadData === true) {
        this.messages.length = 0
      }

      this.messages.push(...messages)
      this.toPage++
      this.loading = false
      this.refreshing = false
    },
    onRefresh() {
      let reloadData = true

      this.toPage = 1

      this.finished = false

      this.loading = true
      this.onLoad(reloadData)
    },
    async showMessageDetail(id, matchIndex) {
      if (this.contents[matchIndex]) {
        this.detail.show = true
        this.detail.content = this.contents[matchIndex].content
        this.detail.title = this.contents[matchIndex].subject

        return this.contents[matchIndex]
      }

      let error, data
      ;[error, data] = await to(this.requestMessageDetail(id))

      if (error) {
        Notify({
          type: 'warning',
          message: error.message || error,
        })
      }

      if (data) {
        this.detail.show = true
        this.detail.content = data.content
        this.detail.title = data.subject
        this.$set(this.contents, matchIndex, data)
      }

      return data
    },
  },
}
</script>

<style lang="scss" scoped>
.lcf-message-list {
  font-size: 16px;
  .lcf-action-sheet {
    padding: 24px;
  }
  .lcf-message-item {
    $urlList: '~@/assets/img/notice/icon_gonggao_anquan.png',
      '~@/assets/img/notice/icon_gonggao_huankuan.png',
      '~@/assets/img/notice/icon_gonggao_huodong.png',
      '~@/assets/img/notice/icon_gonggao_pingtai.png',
      '~@/assets/img/notice/icon_gonggao_qianbao.png',
      '~@/assets/img/notice/icon_gonggao_shuju.png';
    @each $var in $urlList {
      $index: index($urlList, $var);
      &:nth-child(#{$index}n) {
        .lcf-message-item--head {
          .rdm-icon {
            background: content-box left 100% / auto 16px no-repeat url($var);
          }
        }
      }
    }
    .lcf-message-item--head {
      display: flex;
      flex-flow: row, nowrap;
      justify-content: space-between;
      padding: 0 24px;
      height: 24px;
      background: $lcf-color-bg-gray-light;
      span {
        &.lcf-message-time {
          text-align: right;
          line-height: 24px;
          color: $lcf-color-text-gray;
          font-size: 12px;
        }
        flex: 1 1;
      }
    }
    .lcf-message-item--body {
      padding: 16px 24px;
      display: flex;
      flex-flow: column nowrap;
      flex: 1 1;
      .lcf-message-title {
        padding: 8px 0;
      }
      .lcf-message-instr {
        padding: 0 0 8px;
        color: $lcf-color-text-gray;
        font-size: 12px;
      }
    }
  }
}
</style>
